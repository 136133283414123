body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url('./bg-august-23.gif');
  /* Set the background image to cover the entire viewport */
  background-size: cover;
  /* Center the background image */
  background-position: center;
  /* Set the background to fixed to prevent scrolling with content */
  background-attachment: fixed;
  /* Set a fixed height to ensure the background image is visible */
  height: 100vh;

  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}