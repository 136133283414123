.App {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

/* Mobile phones only (usually devices with less than 768px in width) */
@media only screen and (max-width: 767px) {
  .App {
    justify-content: center;
  }

  .logo-container {
    display: flex;
    justify-content: center;
  }
}


.logo-container {
  width: 100%;
}

.logo {
  fill: #fff;
  width: 300px;
  margin: 20px
}

.wrapper {
  max-width: 80%;
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 800px;
  animation: fadeIn 0.6s ease forwards;

  background-color: rgba(22, 44, 37, 0.5);
  /* Background color with 70% opacity */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;

}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


a {
  color: white;
  /* Set the link color to white */
  font-weight: bold;
  text-decoration: underline;
  /* Add underline */
  transition: font-size 0.3s ease;
  /* Transition effect for font-size */
}

a:hover {
  font-size: 105%;
  /* Increase the font size slightly on hover */
}

a:active {
  /* Return to white with underline when clicked */
  color: white;
  text-decoration: underline;
  font-weight: bold;
}


button {
  height: 50px;
  color: #fff;
  background-color: #162c25;
  border: none;

  padding: 10px;
  width: 250px;
  transition: transform 0.3s ease;
  /* Transition the transform property */
}

button:hover {
  transform: scale(1.05);
  /* Scale the button 5% larger when hovered */
}

input[type="text"],
input[type="email"],
input[type="checkbox"]
{
  height: 20px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  border: none;
  padding: 10px;
  width: 300px;
  opacity: 0.8;
  transition: opacity 0.3s ease; /* Transition the border color */
}

select {
  opacity: 0.8;
  height: 20px;
  width: 300px;
  color: #000;
  border: none;
}

input[type="text"]:hover,
input[type="email"]:hover {
  opacity: 1;
}


label {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 5px;
}
